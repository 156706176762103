// ======================================================================
// Hepburn | hello@hashandsalt.com
// ======================================================================

// window.$ = window.jQuery = require('jquery');

// import $ from 'jquery';
// window.$ = window.jQuery = $;

// ======================================================================
// Modules
// ======================================================================

// require any further scripts here ie:
// require('npmpackagename');
// require('./mylocalfile.js');

// Head JS
import "./modules/head.js";

import LazyLoad from "vanilla-lazyload";



// import './modules/webp.js';

// import Swup from "swup";
// import SwupBodyClassPlugin from "@swup/body-class-plugin";

// const swup = new Swup({
//   plugins: [new SwupBodyClassPlugin()],
//   containers: ["#page"],
// });

// Plyr
// import Plyr from "plyr";

// Slider
import { tns } from "tiny-slider/src/tiny-slider";

import $ from "domtastic";



// alert(window.devicePixelRatio)


// Lightbox

// import Tobii from '@midzer/tobii';

// function startInfo() {
//   let info = document.getElementById('info');
//   if (info) {
//     let tobii = new Tobii({
//       captions: false,
//       zoom: false,
//       counter: false,
//     });

//     function kill() {
//       tobii.destroy()
//     }

//     // swup.on("willReplaceContent",  kill);

//   }
// }

// Lazy
function lazyImg() {
  let lazyLoadInstance = new LazyLoad({
    // Your custom settings go here
  });
}

// Menu
function menu() {
  var navlinks = document.querySelectorAll(".nav-link");
  var pane = document.querySelector(".site-menu");
  var bodyClass = document.querySelector("body");


  if (navlinks) {
    Array.from(navlinks).forEach((link) => {
      link.addEventListener(
        "click",
        function () {
          pane.classList.toggle("site-menu-active");
          bodyClass.classList.toggle("menu-open");
          // bodyClass.classList.remove("scroll-up");
          // bodyClass.classList.remove("scroll-down");

        },
        false
      );
    });
  }
}

function closeMenu() {
  var pane = document.querySelector(".site-menu");
  if (pane.classList.contains("site-menu-active")) {
    pane.classList.toggle("site-menu-active");
  }
}


// function videoplay() {
//   let vidbox = document.getElementById("video");
//   if (vidbox) {
//     const player = new Plyr("#video", {
//       clickToPlay: false,
//       constols: ['play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'settings', 'pip', 'airplay', 'fullscreen']
//     });
//     // pane.classList.toggle("site-menu-active");
//     var navlinks = document.querySelectorAll(".nav-link");

//     if (navlinks) {
//       Array.from(navlinks).forEach((link) => {
//         link.addEventListener(
//           "click",
//           function () {
//             player.pause();
//           },
//           false
//         );
//       });
//     }
//   }
// }

var pane = document.querySelector(".site-menu");

function sliders() {
  var slideboxes = document.querySelector(".exhibit-gallery");
  if (slideboxes) {
    const tnsCarousel = document.querySelectorAll(".exhibit-gallery");
    tnsCarousel.forEach((slider) => {
      let tnsslider = tns({
        container: slider,
        items: 1,
        slideBy: "page",
        autoplay: false,
        controls: true,
        nav: true,
      });

      let info = tnsslider.getInfo();
      let box = $(slider).parent().parent().parent().siblings(".counter");

      let slideNumber = box.children(".slide-num");
      let slideTotal = box.children(".slide-total");

      slideNumber.text(info.displayIndex);
      slideTotal.text(info.slideCount);

      var setCount = function (info, eventName) {
        slideNumber.text(info.displayIndex);
      };
      tnsslider.events.on("transitionEnd", setCount);
    });
  }
}

// Macro Timer
let inactivityTime = function () {
  let macropage = document.getElementById("mp");
  if (macropage) {
    let time;
    document.onload = resetTimer;
    document.onmousemove = resetTimer;
    document.onmousedown = resetTimer; // touchscreen presses
    document.ontouchstart = resetTimer;
    document.onclick = resetTimer; // touchpad clicks
    document.onkeydown = resetTimer; // onkeypress is deprectaed
    document.addEventListener("scroll", resetTimer, true); // improved; see comments

    function logout() {
      // alert("Timer reached")
      location.href = "/index.html";
    }

    function resetTimer() {
      clearTimeout(time);
      time = setTimeout(logout, 1000);
      // 1000 milliseconds = 1 second
    }
  }
};


function zoomy() {
  let panocontainer = document.getElementById("pano");
  let body = document.querySelector("html");
  if (panocontainer) {
    var krpano = null;
    embedpano({
      xml: panocontainer.dataset.xml,
      target: "pano",
      html5: "only",
      mobilescale: 1.0,
      wmode: "transparent",
      passQueryParameters: true,
      initvars: {
        skin: (body.classList.contains('desktop-true') ? '/assets/skin/eeskin.xml' : '/assets/skin/eeskin-mobile.xml'),  
        setup: panocontainer.dataset.setup,
        maxbugzoom: 3,
        bugscale: panocontainer.dataset.bugscale,
        // level1: panocontainer.dataset.level1,
        // level2: panocontainer.dataset.level2,
        // level3: panocontainer.dataset.level3,
        // level4: panocontainer.dataset.level4,
        // level5: panocontainer.dataset.level5,
        // level6: panocontainer.dataset.level6,
        // level7: panocontainer.dataset.level7,
        // level8: panocontainer.dataset.level8,
      },
      onready: krpano_onready_callback,
    });
    function krpano_onready_callback(krpano_interface) {
      krpano = krpano_interface;
    }
  }
}

function pinchPull() {
  var mobile = document.querySelector(".touch-true");
  var zoomInstruction = document.querySelector(".pinchpull");
  if (mobile) {
    if (zoomInstruction) {
      setTimeout(function () {
        zoomInstruction.classList.add("hide");
      }, 1500);
    }
  }
}

function rolls() {
  let rolls = document.querySelectorAll(".roll");

  for (let i = 0; i < rolls.length; i++) {
    let txt = rolls[i].innerHTML;
    // console.log(txt);
    txt.split("");
    rolls[i].innerHTML = "";
    for (let ix = 0; ix < txt.length; ix++) {
      rolls[i].innerHTML +=
        '<span aria-hidden="true" style="--ch-id:' +
        ix +
        '">' +
        txt[ix] +
        "</span>";
    }
  }
}
function rollsInvert() {
  let rolls = document.querySelectorAll(".rollinvert");

  for (let i = 0; i < rolls.length; i++) {
    let txt = rolls[i].innerHTML;
    // console.log(txt);
    txt.split("");
    rolls[i].innerHTML = "";
    for (let ix = 0; ix < txt.length; ix++) {
      rolls[i].innerHTML +=
        '<span aria-hidden="true" style="--ch-id:' +
        ix +
        '">' +
        txt[ix] +
        "</span>";
    }
  }
}

function thumbFade() {
  var macrolist = document.querySelector(".macro-list");
  if (macrolist) {
    const observerOptions = {
      root: null,
      threshold: 0,
      rootMargin: "0px 0px -50px 0px",
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("thumb-in-view");
          observer.unobserve(entry.target);
        }
      });
    }, observerOptions);

    window.addEventListener("DOMContentLoaded", (event) => {
      const sections = Array.from(document.getElementsByClassName("fademeup"));

      for (let section of sections) {
        observer.observe(section);
      }
    });
  }
}

function textFade() {
  var list = document.querySelector(".info-section .longform");
  if (list) {
    const observerOptions = {
      root: null,
      threshold: 0,
      rootMargin: "0px 0px -12px 0px",
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("text-in-view");
          observer.unobserve(entry.target);
        }
      });
    }, observerOptions);

    window.addEventListener("DOMContentLoaded", (event) => {
      let sections = Array.from(
        document.querySelectorAll(
          ".longform p, .longform hr, .longform h1, .longform h2, .longform ul, .longform figure, .fast-facts"
        )
      );
      // console.log(sections);

      for (let section of sections) {
        observer.observe(section);
      }
    });
  }
}

function exFade() {
  var ex = document.querySelector(".exhibitions-section");
  if (ex) {
    const observerOptions = {
      root: null,
      threshold: 0,
      rootMargin: "0px 0px -12px 0px",
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("ex-in-view");
          observer.unobserve(entry.target);
        }
      });
    }, observerOptions);

    window.addEventListener("DOMContentLoaded", (event) => {
      let sections = Array.from(document.querySelectorAll(".ex-fade"));
      // console.log(sections);

      for (let section of sections) {
        observer.observe(section);
      }
    });
  }
}




function init() {
  lazyImg();
  // startInfo();
  // inactivityTime();
  menu();
  closeMenu();
  sliders();
  zoomy();
  // videoplay();
  pinchPull();
  rolls();
  rollsInvert();
  textFade();
  thumbFade();
  exFade();

}

document.addEventListener('DOMContentLoaded', (event) => {
  init();
});


// swup.on("contentReplaced", init);
